<template>
  <div class="home">
    <div class="terminal-header">
      <a class="clickable back-link" @click="$router.push({ name: 'home' })">
        <b-icon icon="arrow-left-circle-fill" variant="white" class="ml-3 mr-2 mt-2" font-scale="2"/>
      </a>

      <div class="pt-3 pr-4" style="float: right; color: white;">
        <span class="btn btn-danger mr-3" @click="showRevertModal" v-b-tooltip.hover.bottom="$t('STUDENT_MACHINE.ACTIONS.revert')">
          <font-awesome-icon :icon="['fas', 'history']" />
        </span>
        <span class="btn btn-danger mr-3" @click="showRestartModal" v-b-tooltip.hover.bottom="$t('STUDENT_MACHINE.ACTIONS.restart')">
          <font-awesome-icon :icon="['fas', 'undo']" />
        </span>
      </div>
    </div>
    <loading :loading="loading">
      <template v-slot:content>
        <div class="text-center">
        <b-progress variant="success" animated :value="loadProgress" style="width: 200px;" />
        <span class="mt-2" style="display: block;">
          {{ currentConsoleStatus }}
        </span>
        </div>
      </template>
    </loading>
    <div ref="terminalContent" class="terminal-content">
      <iframe ref="consola" :src="consoleUrl" frameborder="0" v-if="hasMachineUrl && !loading" ></iframe>
      <NoResults v-if="!hasMachineUrl && !loading" :title="$t('GENERAL.oops')" :msg="$t('STUDENT_MACHINE.ERRORS.no_machine')" />
    </div>

    <!-- Modal de revertir -->
    <b-modal
      ref="modalRevert"
      :title="$t('STUDENT_MACHINE.confirm_revert_title')"
      :ok-title="$t('ACTIONS.accept')"
      :cancel-title="$t('ACTIONS.cancel')"
      @ok="revertStudentMachine"
    >
      <p>{{ $t('STUDENT_MACHINE.confirm_revert_text') }}</p>
    </b-modal>

    <b-modal
      ref="modalRestart"
      :title="$t('STUDENT_MACHINE.confirm_restart_title')"
      :ok-title="$t('ACTIONS.accept')"
      :cancel-title="$t('ACTIONS.cancel')"
      @ok="restartStudentMachine"
    >
      <p>{{ $t('STUDENT_MACHINE.confirm_restart_text') }}</p>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import NoResults from '@/components/utils/NoResults'

import ToastsMixin from '@/services/mixins/toasts.mixins'

import { STUDENT_MACHINE_START_STATUS, STUDENT_MACHINE_BOOT_STATUS } from '@/config/constants'

export default {
  name: 'TerminalPage',
  mixins: [ToastsMixin],
  components: { NoResults },
  data () {
    return {
      loading: false,
      checkStatusIntervalId: null,
      simulateProgressInterval: null,
      keepAliveInterval: null,
      consoleStartStatus: '',
      consoleStatus: '',
      consoleBoot: '',
      loadProgress: 0,
      url: ''
    }
  },
  watch: {
    canSeeUserConsole () {
      if (this.canSeeUserConsole != null && !this.canSeeUserConsole) {
        this.$router.push({ name: 'error', params: { error: 403 } })
      }
    }
  },
  methods: {
    ...mapActions({
      startConsoleAction: 'labs/startConsole',
      restartConsoleAction: 'labs/restartConsole',
      revertConsoleAction: 'labs/revertConsole',
      checkConsoleStatusAction: 'labs/checkConsoleStatus',
      getOnlyUser: 'auth/getOnlyUser'
    }),
    showRevertModal () {
      this.$refs.modalRevert.show()
    },
    revertStudentMachine () {
      this.consoleStartStatus = 'reverting'
      this.startCheckConsole()
      this.loading = true

      return this.revertConsoleAction()
        .then(_ => this.startConsole())
        .catch(_ => {
          this.showErrorToast(this.$t('STUDENT_MACHINE.ERRORS.revert'))
          this.stopCheckConsole()
        })
    },
    showRestartModal () {
      this.$refs.modalRestart.show()
    },
    restartStudentMachine () {
      this.consoleStartStatus = 'restarting'
      this.startCheckConsole()
      this.loading = true

      return this.restartConsoleAction()
        .then(_ => this.startConsole())
        .catch(_ => {
          this.showErrorToast(this.$t('STUDENT_MACHINE.ERRORS.restart'))
          this.stopCheckConsole()
        })
    },
    startConsole () {
      this.consoleStartStatus = 'starting'
      return this.startConsoleAction(this.labId)
        .then(response => {
          if (typeof response.data.data.url === 'undefined') throw Error('missing_url')

          this.url = response.data.data.url
          this.consoleStartStatus = 'started'
        })
        .catch(_ => {
          this.showErrorToast(this.$t('LABS.ERRORS.general'))
          this.consoleStartStatus = 'error'
          this.stopCheckConsole()
        })
    },
    checkConsoleStatus () {
      if (this.consoleStartStatus === 'starting') return
      if (this.requestingConsoleStatus) return

      this.requestingConsoleStatus = true

      return this.checkConsoleStatusAction()
        .then(data => {
          this.parseConsoleStatus(data)
        })
        .catch(_ => {})
        .then(_ => { this.requestingConsoleStatus = false })
    },
    parseConsoleStatus (data) {
      if (typeof data.status === 'undefined' || !data.status) return
      if (typeof data.data === 'undefined') return

      this.consoleStatus = data.data.state
      this.consoleBoot = data.data.boot

      if (this.consoleStatus === STUDENT_MACHINE_START_STATUS.RUNNING && this.consoleBoot === STUDENT_MACHINE_BOOT_STATUS.RUNNING) {
        this.$emit('console-started')
      }
    },
    simulateProgress () {
      if (this.loadProgress < 90) this.loadProgress = this.loadProgress + 1
    },
    clearLoadingProgress () {
      this.loadProgress = 0
    },
    startCheckConsole () {
      this.checkStatusIntervalId = setInterval(this.checkConsoleStatus, 3000)
      this.simulateProgressInterval = setInterval(this.simulateProgress, 1000)
    },
    stopCheckConsole () {
      this.loadProgress = 100

      clearInterval(this.checkStatusIntervalId)
      clearInterval(this.simulateProgressInterval)

      setTimeout(this.clearLoadingProgress, 1500)

      // We give a small margin to avoid strange states
      setTimeout(_ => { this.loading = false }, 1000)
    },
    consoleKeepAlive () {
      this.keepAliveInterval = setInterval(this.getOnlyUser, this.consoleKeepAliveTimeInterval * 60 * 1000)
    }
  },
  computed: {
    ...mapGetters({
      canSeeUserConsole: 'config/canSeeUserConsole'
    }),
    hasMachineUrl () {
      return this.url !== null && this.url !== ''
    },
    hasPassedAwsChecks () {
      return this.consoleStatus === STUDENT_MACHINE_START_STATUS.RUNNING && this.consoleBoot === STUDENT_MACHINE_BOOT_STATUS.RUNNING
    },
    consoleUrl () { return this.hasMachineUrl && this.hasPassedAwsChecks ? this.url : '' },

    currentConsoleStatus () {
      if (this.consoleStartStatus === 'restarting') return this.$t('STUDENT_MACHINE.LOADING_STATUS.restarting')
      if (this.consoleStartStatus === 'reverting') return this.$t('STUDENT_MACHINE.LOADING_STATUS.reverting')
      if (this.consoleStartStatus === 'starting') return this.$t('STUDENT_MACHINE.LOADING_STATUS.starting')

      if (this.consoleStatus === STUDENT_MACHINE_START_STATUS.PENDING && this.consoleBoot === STUDENT_MACHINE_BOOT_STATUS.PENDING) return this.$t('STUDENT_MACHINE.LOADING_STATUS.starting')
      if (this.consoleStatus === STUDENT_MACHINE_START_STATUS.RUNNING && this.consoleBoot === STUDENT_MACHINE_BOOT_STATUS.IN_PROGRESS) return this.$t('STUDENT_MACHINE.LOADING_STATUS.starting_os')
      if (this.consoleStatus === STUDENT_MACHINE_START_STATUS.RUNNING && this.consoleBoot === STUDENT_MACHINE_BOOT_STATUS.RUNNING) return this.$t('STUDENT_MACHINE.LOADING_STATUS.ready')

      return this.$t('STUDENT_MACHINE.LOADING_STATUS.starting')
    },
    consoleKeepAliveTimeInterval () {
      return parseInt(process.env.VUE_APP_CONSOLE_LEEP_ALIVE_TIME_INTERVAL) || 5
    }
  },
  mounted () {
    this.loading = true
    this.startConsole()

    /**
     * Refocuses the iframe containing Guacamole if the user is not already
     * focusing another non-body element on the page.
     */
    var refocusGuacamole = function refocusGuacamole () {
      // Do not refocus if focus is on an input field
      var focused = document.activeElement
      if (focused && focused !== document.body) { return }

      // Ensure iframe is focused
      if (typeof this.$refs.consola !== 'undefined') this.$refs.consola.focus()
    }.bind(this)

    // Attempt to refocus iframe upon click or keydown
    document.addEventListener('click', refocusGuacamole)
    document.addEventListener('keydown', refocusGuacamole)

    // Student console status
    this.startCheckConsole()

    this.consoleKeepAlive()

    this.$on('console-started', _ => {
      this.stopCheckConsole()
    })
  },
  beforeDestroy () {
    clearInterval(this.checkStatusIntervalId)
    clearInterval(this.keepAliveInterval)
    clearInterval(this.simulateProgressInterval)
  }
}
</script>

<style lang="scss" scoped>

.terminal-header {
  height: $header-height;
  background-color: var(--primary-dark);
  width: 100%;

  .back-link {
    line-height: 90px;
  }
}

.terminal-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: calc(100vh - #{$header-height});
  background-color: var(--primary-light);

  iframe {
    height: 100%;
    width: 100%;
  }
}
</style>
